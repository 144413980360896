import { Conf } from '@services/conf/types';

export const defaultConf: Conf = {
  environment: 'Local development',
  debug: false,
  languages: ['es', 'en'],
  api: {
    url: 'https://api-tms.majesticresorts.com/api-gateway', // production
    //url: 'https://api-odata.test.majesticresorts.com/api-gateway', // test
    user: 'checkin-user',
    password: 'mVI5@-,J&99U',
  },
  email: {
    providers: {
      aws: {
        //url: 'https://dja434qmae.execute-api.eu-south-2.amazonaws.com/test/send-mail', // kritical lab (test)
        url: 'https://api-odata.test.majesticresorts.com/api-gateway', // production
      },
    },
  },
  monitor: {
    providers: {
      sentry: {
        dsn: 'https://02ee7081f13129e9d19aab362f944082@o1381156.ingest.us.sentry.io/4507302903283712',
      },
    },
  },
  defaults: {
    fillContraints: {
      AD: 'required',
      JR: 'optional',
      NI: 'optional',
      CU: 'none',
    },
    language: 'en',
  },
  hotels: [
    {
      id: 'M1',
      enabled: true,
      name: 'Majestic Colonial Punta Cana',
      country: 'dr',
      vat: '110173752',
      address: 'Playa Arena Gorda, Carr. El Macao - Arena Gorda',
      postalCode: '23000',
      city: 'Punta Cana',
      state: 'La Altagracia',
      password: 'M1',
      wifi: {
        ssid: 'Majestic_WIFI',
        password: 'Happystay!',
        captivePortal: false,
      },
    },
    {
      id: 'M2',
      enabled: true,
      name: 'Majestic Elegance Punta Cana',
      country: 'dr',
      vat: '110173752',
      address: 'Playa Arena Gorda, Carr. El Macao - Arena Gorda',
      postalCode: '23000',
      city: 'Punta Cana',
      state: 'La Altagracia',
      password: 'M2',
      wifi: {
        ssid: 'Majestic_WIFI',
        password: 'Happystay!',
        captivePortal: false,
      },
    },
    {
      id: 'M3',
      enabled: true,
      name: 'Majestic Mirage Punta Cana',
      country: 'dr',
      vat: '110173752',
      address: 'Playa Arena Gorda, Carr. El Macao - Arena Gorda',
      postalCode: '23000',
      city: 'Punta Cana',
      state: 'La Altagracia',
      password: 'M3',
      wifi: {
        ssid: 'Majestic_WIFI',
        password: 'Happystay!',
        captivePortal: false,
      },
    },
    {
      id: 'M4',
      enabled: true,
      name: 'Majestic Elegance Costa Mujeres',
      country: 'mx',
      vat: '110173752',
      address: 'Playa Mujeres, Carretera libre 85 Punta Sam, Isla Blanca 85, Supermanzana 6 Manzana 2',
      postalCode: '77400',
      city: 'Costa Mujeres',
      state: 'Quintana Roo',
      password: 'M4',
      wifi: {
        ssid: 'Majestic_WIFI',
        captivePortal: true,
      },
    },
  ],
};
